.infinite-scroll-component__outerdiv {
  flex: 1;

  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px;
    }
  }
}
